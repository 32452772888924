body{
  background-color: #000 !important;
}
.react-chatbot-kit-chat-container {
  border-radius: 10px;
}


.react-chatbot-kit-chat-header {
  background-color: #4a90e2;  /* Cambia el color de fondo */
  color: white;               /* Cambia el color del texto */
  font-size: 14px;            /* Cambia el tamaño de la fuente */
  font-weight: bold;          /* Hace el texto en negrita */
  text-align: center;         /* Centra el texto */
  padding: 15px;              /* Añade espacio interno */
  border-radius: '50%';
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  font-size: 0.85rem;
  border: 1px solid #e8eae8 !important;
  border-top: 1px solid #e8eae8 !important;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-inner-container {
  border: 1px solid #e8eae8 !important;
  font-family: monospace;
}

.custom-bot-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #2898ec;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  transform-origin: right bottom;
  /*overflow: hidden;*/
margin-right: 10px;
}

.color-h4{
  color: black !important;
}

.color-h4-vendido{
  color: white !important;
}


.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 10px 17.5px;
  overflow: scroll;
  height: 424px;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-image-custom {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #212529;
/*   background-color: #0062f3 !important;
  border-color: #fff !important; */
}

.product-item .btn:hover {
  color: #fff !important;
}

.btn-primary {
  color: #fff !important;;
  background-color: #0062f3 !important;;
  border-color: #58bae8 !important;;
}

.btn-primary:hover {
  color: #fff !important;;
  background-color: #0062f3 !important;;
  border-color: #58bae8 !important;;
}

.btn.btn-primary {
  color: #ffffff !important;
}

.loading-spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}